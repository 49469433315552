<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->
      <div class="row">
        <div class="col-xl-12">
          <h1>
            Estado de la descarbonización en zonas rurales de Informe I, II y
            III*
          </h1>
        </div>
      </div>
      <v-row>
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              Contribución a la descarbonización en zonas rurales
            </v-card-title>
            <v-card-text>
              <div id="chart-front-1">
                <fusioncharts
                  :type="chart1.type"
                  :width="chart1.width"
                  :height="500"
                  :data-format="chart1.dataFormat"
                  :data-source="chart1.dataSource"
                />
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
      <v-row>
        <div class="row">
          <div class="col-xl-4">
            <v-card
              class="mx-auto rounded-lg ecosistema-ve"
              elevation="10"
              outlined
            >
              <v-card-title>
                <v-avatar size="56">
                  <img
                    alt="Vehículo Eléctrico"
                    src="/media/cide/ecosistemas/VE.png"
                  />
                </v-avatar>
                <p class="ml-3 texto-ecosistema-ve">Vehículo Eléctrico</p>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Contribución a la descarbonización del vehículo eléctrico
                      en el medio rural Informe I*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black">11,08 </span>
                    toneladas CO2 equivalente evitadas/10.000 habitantes
                  </div>
                </v-row>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Contribución a la descarbonización del vehículo eléctrico
                      en el medio rural Informe II*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black">16,98 </span>
                    toneladas CO2 equivalente evitadas/10.000 habitantes
                  </div>
                </v-row>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Contribución a la descarbonización del vehículo eléctrico
                      en el medio rural Informe III*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black">24,74 </span>
                    toneladas CO2 equivalente evitadas/10.000 habitantes
                  </div>
                </v-row>
                <v-divider />
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Nº vehículos eléctricos registrados en zonas rurales
                      Informe I*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.nve }}
                    </span>
                  </div>
                </v-row>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Nº vehículos eléctricos registrados en zonas rurales
                      Informe II*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.nveNew }}
                    </span>
                  </div>
                </v-row>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Nº vehículos eléctricos registrados en zonas rurales
                      Informe III*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.nveNewest }}
                    </span>
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-xl-4">
            <v-card
              height="100%"
              class="mx-auto rounded-lg ecosistema-aut"
              elevation="10"
              outlined
            >
              <v-card-title>
                <v-avatar size="56">
                  <img
                    alt="Autoconsumo"
                    src="/media/cide/ecosistemas/AUT.png"
                  />
                </v-avatar>
                <p class="ml-3 texto-ecosistema-aut">Autoconsumo</p>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Despliegue del autoconsumo en hogares rurales Informe I*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-medium"
                      >{{ dataJson.aut }} </span
                    >% de los hogares rurales cuenta con autoconsumo
                  </div>
                </v-row>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Despliegue del autoconsumo en hogares rurales Informe II*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-medium"
                      >{{ dataJson.autNew }} </span
                    >% de los hogares rurales cuenta con autoconsumo
                  </div>
                </v-row>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Despliegue del autoconsumo en hogares rurales Informe
                      III*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-medium"
                      >{{ dataJson.autNewest }} </span
                    >% de los hogares rurales cuenta con autoconsumo
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-xl-4">
            <v-card
              class="mx-auto rounded-lg ecosistema-elc"
              elevation="10"
              outlined
              height="100%"
            >
              <v-card-title>
                <v-avatar size="56">
                  <img
                    alt="Nivel de electrificación"
                    src="/media/cide/ecosistemas/ELC.png"
                  />
                </v-avatar>
                <p class="ml-3 texto-ecosistema-elc">Electrificación</p>
              </v-card-title>
              <v-card-text>
                <div class="row">
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Grado de electrificación en zonas rurales Informe I*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.elc }}
                    </span>
                    kWh anuales/suministro doméstico rural
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Grado de electrificación en zonas rurales Informe II*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.elcNew }}
                    </span>
                    kWh anuales/suministro doméstico rural
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Grado de electrificación en zonas rurales Informe III*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.elcNewest }}
                    </span>
                    kWh anuales/suministro doméstico rural
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <v-card
              class="mx-auto rounded-lg ecosistema-alm"
              elevation="10"
              outlined
              height="100%"
            >
              <v-card-title>
                <v-avatar size="56">
                  <img
                    alt="Almacenamiento"
                    src="/media/cide/ecosistemas/ALM.png"
                  />
                </v-avatar>
                <p class="ml-3 texto-ecosistema-alm">Almacenamiento</p>
              </v-card-title>
              <v-card-text>
                <div class="row">
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Hogares rurales con almacenamiento eléctrico Informe I*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.alm }} % </span
                    >de los hogares rurales cuenta con almacenamiento
                  </div>
                </div>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Hogares rurales con almacenamiento eléctrico Informe II*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.almNew }} % </span
                    >de los hogares rurales cuenta con almacenamiento
                  </div>
                </v-row>
                <v-row>
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Hogares rurales con almacenamiento eléctrico Informe III*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.almNewest }} % </span
                    >de los hogares rurales cuenta con almacenamiento
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-xl-4">
            <v-card
              class="mx-auto rounded-lg ecosistema-con"
              elevation="10"
              outlined
              height="100%"
            >
              <v-card-title>
                <v-avatar size="56">
                  <img
                    alt="Concienciación"
                    src="/media/cide/ecosistemas/CON.png"
                  />
                </v-avatar>
                <p class="ml-3 texto-ecosistema-con">Concienciación</p>
              </v-card-title>
              <v-card-text>
                <div class="row">
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Grado de preocupación de hogares rurales por la
                      contaminación y la emisión de GEI Informe I*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.con }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Grado de preocupación de hogares rurales por la
                      contaminación y la emisión de GEI Informe II*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.conNew }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8">
                    <span class="titulo font-weight-medium"
                      >Grado de preocupación de hogares rurales por la
                      contaminación y la emisión de GEI Informe III*
                    </span>
                  </div>
                  <div class="col-xl-4">
                    <span class="dato font-weight-black"
                      >{{ dataJson.conNewest }}
                    </span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-xl-4">
            <v-card
              class="mx-auto rounded-lg ecosistema-gen"
              elevation="10"
              outlined
            >
              <v-card-title>
                <v-avatar size="56">
                  <img alt="Generación" src="/media/cide/ecosistemas/GEN.png" />
                </v-avatar>
                <p class="ml-3 texto-ecosistema-gen">
                  Generación libre de emisiones
                </p>
              </v-card-title>

              <v-card-text>
                <div class="row">
                  <div class="col-xl-12">
                    <span class="titulo font-weight-medium"
                      >88% de la potencia instalada de energía renovable en
                      España se encuentra ubicada en el medio rural 2020.
                    </span>
                  </div>
                  <div class="col-xl-12">
                    <span class="titulo font-weight-medium"
                      >38% de la energía consumida en España es de origen
                      renovable y rural 2020*
                    </span>
                  </div>
                  <div class="col-xl-12">
                    <span class="titulo font-weight-medium"
                      >41 millones de toneladas de CO2 equivalente evitadas
                      gracias a la generación renovable rural 2020**
                    </span>
                  </div>
                  <div class="col-xl-12">
                    <span class="titulo font-weight-medium"
                      >87% de la potencia instalada de energía renovable en
                      España se encuentra ubicada en el medio rural 2021.
                    </span>
                  </div>
                  <div class="col-xl-12">
                    <span class="titulo font-weight-medium"
                      >40% de la energía consumida en España es de origen
                      renovable y rural 2021*
                    </span>
                  </div>
                  <div class="col-xl-12">
                    <span class="titulo font-weight-medium"
                      >44 millones de toneladas de CO2 equivalente evitadas
                      gracias a la generación renovable rural 2021**
                    </span>
                  </div>
                  <div class="col-xl-12">
                    <span class="titulo font-weight-medium"
                      >86% de la potencia instalada de energía renovable en
                      España se encuentra ubicada en el medio rural 2022.
                    </span>
                  </div>
                  <div class="col-xl-12">
                    <span class="titulo font-weight-medium"
                      >36% de la energía consumida en España es de origen
                      renovable y rural 2022*
                    </span>
                  </div>
                  <div class="col-xl-12">
                    <span class="titulo font-weight-medium"
                      >43 millones de toneladas de CO2 equivalente evitadas
                      gracias a la generación renovable rural 2022**
                    </span>
                  </div>
                  <div>
                    <br />
                    <br />
                    *Dato correspondiente a la generación renovable intermitente
                    instalada en zonas clasificadas como rurales en España y la
                    generación hidráulica y de bombeo instalada en el territorio
                    nacional en el año 2019
                    <br />
                    **Según información para el año 2019 del Inventario Nacional
                    de Gases de Efecto Invernadero (marzo, 2021)
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-row>
      <div class="row">
        <div class="col-xl-12">
          <h1>Objetivos de descarbonización – El papel del medio rural</h1>
        </div>
        <div class="col-xl-4 text-center">
          <v-card height="100%">
            <br />
            <v-avatar size="56">
              <img
                alt="Vehículo Eléctrico"
                src="/media/cide/ecosistemas/VE.png"
              />
            </v-avatar>
            <v-card-text>
              <p>
                En el borrador de actualización del PNIEC el sector de la
                movilidad destaca como uno de los sectores en los que se prevé
                una mayor reducción de emisiones, 32 MtCO2eq. Para lograr este
                objetivo, se incrementan las previsiones del parque de vehículo
                eléctrico alcanzando 5,5 millones de vehículos eléctricos en
                2030. En condiciones equitativas, considerando la flota actual
                de vehículos en zonas rurales, este objetivocorrespondería a la
                introducción de aproximadamente medio millón de vehículos
                eléctricos en el medio rural antes del año 2030.
              </p>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-4 text-center">
          <v-card>
            <br />
            <v-avatar size="56">
              <img
                alt="Vehículo Eléctrico"
                src="/media/cide/ecosistemas/AUT.png"
              />
            </v-avatar>
            <v-card-text>
              <p>
                El borrador de actualización del PNIEC destaca el despliegue del
                autoconsumo producido por la aplicación de su hoja de ruta.
                Gracias a ello, se actualizan las previsiones hasta los 19 GW de
                autoconsumo al reconocer su importancia para alcanzar los
                objetivos de cumplimiento establecidos. En este sentido, si la
                participación del medio rural fuera equitativa, considerando el
                peso de la población rural, el medio rural debería tener
                aproximadamente 3 GW de potencia de autoconsumo renovable
                instalada en el año 2030.
              </p>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-4 text-center">
          <v-card>
            <br />
            <v-avatar size="56">
              <img
                alt="Vehículo Eléctrico"
                src="/media/cide/ecosistemas/ALM.png"
              />
            </v-avatar>
            <v-card-text>
              <p>
                En el borrador de actualización del PNIEC el almacenamiento
                adquiere un mayor protagonismo para reforzar la flexibilidad y
                gestión de la demanda debida a la integración de renovables en
                el mix eléctrico. Para ello, el PNIEC 2023-2030 incrementa las
                previsiones de la Estrategia de Almacenamiento Energético que
                estimaba la disposición de 20 GW y establece unos objetivos de
                22 GW de almacenamiento en 2030. Considerando una participación
                del medio rural equitativa al peso de su población, el medio
                rural debería contar en 2030 con cerca de 3,5 GW de
                almacenamiento.
              </p>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <!--      *-->
      <!--      <p class="mb-0">Informe I: Datos extraídos de los años 2020 y 2021</p>-->
      <!--      <p class="mb-0">Informe II: Datos extraídos de los años 2021 y 2022</p>-->
      <!--      <p>Informe III: Datos extraídos de los años 2022 y 2023</p>-->
      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      dataJson: [],
      chart1: {
        renderAt: "chart-front-1",
        type: "spain",
        width: "90%",
        height: "90%",
        dataFormat: "jsonurl",
        dataSource: "./data/FRONT/mapa.json",
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Inicio" }]);
    this.getJsonData();
  },
  methods: {
    getJsonData() {
      fetch("/data/FRONT/data.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson = data;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

.eqWrap {
  display: flex;
}

.eq {
  padding: 10px;
}

.equalHMRWrap {
  justify-content: space-between;
  flex-wrap: wrap;
}

.equalHMV {
  width: 50%;
  margin: 1%;
}

.titulo {
  font-size: 1.4em;
}

.dato {
  font-size: 1.2em;
}

.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}
.ecosistema-ve {
  border-left: 5px solid #295f56 !important;
}
.ecosistema-aut {
  border-left: 5px solid #63ad41 !important;
}
.ecosistema-elc {
  border-left: 5px solid #439dd9 !important;
}
.ecosistema-alm {
  border-left: 5px solid #133682 !important;
}
.ecosistema-con {
  border-left: 5px solid #666666 !important;
}
.ecosistema-gen {
  border-left: 5px solid #96cdd6 !important;
}
</style>
